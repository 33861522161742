// src/envVars.ts
function isVarTrue(value, defaultValue = false) {
  return value === void 0 ? defaultValue : value.toUpperCase() === "Y" || value.toUpperCase() === "TRUE";
}
function parseIntVar(str, defaultValue = void 0) {
  const parsed = parseInt(str ?? "");
  return isNaN(parsed) ? defaultValue : parsed;
}
function parseFloatVar(str, defaultValue = void 0) {
  const parsed = parseFloat(str ?? "");
  return isNaN(parsed) ? defaultValue : parsed;
}

export {
  isVarTrue,
  parseIntVar,
  parseFloatVar
};
