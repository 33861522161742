globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"e23a469aa3e7ea2fe3463736ca906491aac21c95"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { isVarTrue } from "@kamio/utils";
import * as Sentry from "@sentry/nextjs";
import { supabaseIntegration } from "@supabase/sentry-js-integration";
import { SupabaseClient } from "@supabase/supabase-js";

Sentry.init({
  dsn: "https://6bda40f0b5a0373ae0c542f347f2cf8b@o4506828365627392.ingest.us.sentry.io/4507368055373824",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  enabled: !isVarTrue(process.env.NEXT_PUBLIC_SENTRY_DISABLED),
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: isVarTrue(process.env.NEXT_PUBLIC_SENTRY_DEBUG, false),

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    supabaseIntegration(SupabaseClient, Sentry, {
      tracing: true,
      breadcrumbs: true,
      errors: true,
    }),
    Sentry.browserTracingIntegration({
      shouldCreateSpanForRequest: (url) => {
        return !url.startsWith(`${process.env.NEXT_PUBLIC_SUPABASE_URL}/rest`);
      },
    }),
  ],
});

// if (process.env.NODE_ENV === "development") {
//   Spotlight.init();
// }
